import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../layouts/layout';
import SEO from '../components/seo';

const NotFoundPage = ({ location, data }) => {
  const wpData = data && data.allWordpressPage.edges[0].node.acf;
  return (
    <Layout
      location={location}
      footerData={
      {
        footerLink1: wpData.footer_link_1,
        footerLink2: wpData.footer_link_2,
        footerLink3: wpData.footer_link_3,
        footerLink4: wpData.footer_link_4,
        footerLink5: wpData.footer_link_5,
        footerLink6: wpData.footer_link_6,
        footerLink7: wpData.footer_link_7,
        footerEmailLabel: wpData.footer_email_label,
        footerEmail: wpData.footer_email,
        footerPhoneLabel: wpData.footer_phone_label,
        footerPhone: wpData.footer_phone,
      }
    }
    >
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};
NotFoundPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
NotFoundPage.defaultProps = {
  data: null,
};

export default NotFoundPage;
export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {
            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
          }
          title
          slug
        }
      }
    }
  }`;
